import React from "react";
import { Grid, Typography } from "@mui/material";
import HowToAccordion from "../components/howToAccordion";
import HowToDiagram from "../components/howToDiagram";
import Layout from "../components/layout";
import WorksWith from "../components/worksWith";

const AboutPage = () => {
  return (
    <Layout title="About">
      <Grid maxWidth="lg">
        <Grid item xs={12} md={8}>
          <Typography component="h1" variant="h3" sx={{ mt: 2 }}>
            About
          </Typography>
          <Typography variant="body1">
            Grabbing a Snack was born out of a desire to share cheeky and
            practical messages while in a virtual meeting. Afterall, sometimes
            you need to just quickly step away to grab a snack or let folks know
            you will be a minute.
          </Typography>
          <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
            How Does it Work?
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <b>Scenario 1</b>, you are in the midst of a meeting and need to
            step away for a coffee, snack, or Uber Eats delivery. You could drop
            a note in chat and simply disappear or you could throw up a friendly
            message which allows folks to see where you went!
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <b>Scenario 2</b>, you've been in back to back meetings and need to
            take a quick break. Rather than leaving folks hanging and pinging
            you on Slack, join the meeting and put up a Grabbing a Snack message
            to alert folks that you'll be a minute.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <HowToDiagram />
        </Grid>
        <Grid item xs={12} md={8}>
          <HowToAccordion />
        </Grid>
        <Grid item xs={12}>
          <WorksWith />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AboutPage;
